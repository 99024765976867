import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.primary.main,
  },
  lineBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  leftBox: {
    alignSelf: 'center',
    width: '35%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
  },
  rightBox: {
    alignItems: 'center',
    width: '65%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lineGrid: {
    height: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  sectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  hostsInput: {
    minWidth: theme.spacing(8),
  },
  checkIconRoot: {
    minWidth: 32,
    color: theme.palette.primary.light,
    flexShrink: 0,
    display: 'inline-flex'
  },
  listItem: {
    paddingLeft: 0,
  },
  error: {
    color: 'rgb(193, 0, 0)',
    position: 'absolute',
    top: theme.spacing(7),
    left: 0,
    overflow: 'hidden',
    height: 20,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: theme.spacing(27),
  },
  couponBox: {
    position: 'relative'
  },
  amount: {
    textAlign: 'right'
  }
}));

export default useStyles;
