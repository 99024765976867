import React from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { RootState } from '../../reducers';
import { formatCurrency } from '../../utils/locale';
import { pstnUsAddonChanged, pstnIntlAddonChanged } from './orderSlice';

const AudioConferencingAddon = () => {
  const dispatch = useDispatch();
  const billingInterval = useSelector((state: RootState) => state.order.billingInterval);
  const hosts = useSelector((state: RootState) => state.order.hosts);
  const availableAddons = useSelector((state: RootState) => state.addons.data);
  const selectedAddons = useSelector((state: RootState) => state.order.addonProducts);
  const ownedItems = useSelector((state: RootState) => state.account.data?.subscription?.items?.data);
  const pstnUs = availableAddons?.find(a => a.addOnType === 'pstnUSCanada');
  const pstnIntl = availableAddons?.find(a => a.addOnType === 'pstnIntl');
  const pstnUsChecked = !!selectedAddons?.find(a => a.addOnType === 'pstnUSCanada');
  const pstnIntlChecked = !!selectedAddons?.find(a => a.addOnType === 'pstnIntl');
  const disablePstnIntl = !!ownedItems?.find((item: any) => item.plan.product === pstnIntl?.id);
  const disablePstnUs = disablePstnIntl || !!ownedItems?.find((item: any) => item.plan.product === pstnUs?.id);
  const pstnUsUnitPrice = (billingInterval === 'monthly' ? pstnUs?.monthlyPlan?.unitPrice : pstnUs?.annualPlan?.unitPrice) || 0;
  const pstnIntlUnitPrice = (billingInterval === 'monthly' ? pstnIntl?.monthlyPlan?.unitPrice : pstnIntl?.annualPlan?.unitPrice) || 0;
  const pstnUsPrice = pstnUsUnitPrice * hosts;
  const pstnIntlPrice = pstnIntlUnitPrice * hosts;
  const pstnUsPriceLabel = billingInterval === 'monthly' ?
    intl.get('addons.audioConferencingUsCanadaMonthlyCost', { monthlyPrice: formatCurrency(pstnUsPrice) }) :
    intl.get('addons.audioConferencingUsCanadaAnnualCost', { monthlyPrice: formatCurrency(pstnUsPrice / 12), annualPrice: formatCurrency(pstnUsPrice) });
  const pstnIntlPriceLabel = billingInterval === 'monthly' ?
    intl.get('addons.audioConferencingWorldMonthlyCost', { monthlyPrice: formatCurrency(pstnIntlPrice) }) :
    intl.get('addons.audioConferencingWorldAnnualCost', { monthlyPrice: formatCurrency(pstnIntlPrice / 12), annualPrice: formatCurrency(pstnIntlPrice) });

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary aria-controls="audio-conferencing-content" aria-label="Expand" id="audio-conferencing-header" expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{intl.get('addons.audioConferencing')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
        <FormControl component="fieldset" margin="dense" disabled={disablePstnUs}>
          <FormGroup>
            <FormControlLabel
              aria-label="audio-conferencing-us-canada"
              checked={pstnUsChecked}
              name="audio-conferencing-us-canada"
              control={<Checkbox onChange={(_, value) => dispatch(pstnUsAddonChanged(value))} />}
              label={pstnUsPriceLabel}
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" margin="dense" disabled={disablePstnIntl}>
          <FormGroup>
            <FormControlLabel
              aria-label="audio-conferencing-international"
              name="audio-conferencing-international"
              checked={pstnIntlChecked}
              control={<Checkbox onChange={(_, value) => dispatch(pstnIntlAddonChanged(value))} />}
              label={pstnIntlPriceLabel}
            />
          </FormGroup>
        </FormControl>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AudioConferencingAddon;
