import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Product } from '@lifesize/types/lib/ecommerce';
import ecommerceApi from '../../api/ecommerce';

export type ProductsState = {
  data: Product[] | null;
  error?: SerializedError;
  isLoading: boolean;
}

export const getProducts = createAsyncThunk('products/get', async () => await ecommerceApi.getProducts());

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    isLoading: false
  } as ProductsState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = undefined;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  }
});

export default productsSlice.reducer;
