import { Account, Order, Coupon } from '@lifesize/types/lib/ecommerce';
import OrderState from '../features/order/OrderState';

function build(orderState: OrderState, account?: Account, coupon?: Coupon) {
  const { hosts, selectedBaseProduct, billingInterval, postalCode, reseller, addonProducts } = orderState;
  if (!hosts) {
    throw new Error('Number of hosts not specified');
  }
  if (!selectedBaseProduct) {
    throw new Error('Product not specified');
  }
  if (hosts < selectedBaseProduct.minQuantity) {
    throw new Error('Minimum number of seats not reached');
  }

  const items = [];
  let description = `Lifesize subscription for ${account?.displayName}`;
  if (account?.companyName) {
    description += ` / ${account.companyName}`;
  }

  // Base Plan
  const basePlanId = billingInterval === 'monthly' && selectedBaseProduct.monthlyPlan ?
    selectedBaseProduct.monthlyPlan.id :
    selectedBaseProduct.annualPlan?.id;
  if (basePlanId) {
    items.push({
      planId: basePlanId,
      quantity: hosts
    });
  }
  else {
    throw new Error('No base plan found');
  }

  // Add-ons
  addonProducts
    .filter(addon => addon.baseProduct?.includes(selectedBaseProduct.id))
    .forEach(addon => {
      const addonId = billingInterval === 'monthly' && addon.monthlyPlan ?
        addon.monthlyPlan.id :
        addon.annualPlan?.id;
      if (addonId) {
        if (addon.addOnType === 'streaming') {
          items.push({
            planId: addonId,
            quantity: 1
          });
        } else {
          items.push({
            planId: addonId,
            quantity: hosts
          });
        }
      }
    });

  const order: Order = {
    accountId: account?.groupUUID,
    user: account?.displayName,
    email: account?.email,
    company: account?.companyName,
    postalCode,
    currency: 'usd',
    description,
    reseller,
    coupon: coupon?.id,
    items
  };
  return order;
}

export default { build };
