import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import HtmlTooltip from '../../components/HtmlToolTip';
import { Typography, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import { RootState } from '../../reducers';
import { formatCurrency } from '../../utils/locale';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const dateFormat = new Intl.DateTimeFormat(window.navigator.language, { year: 'numeric', month: 'short', day: '2-digit' });

const InfoBubbleContent = () => {
  const classes = useStyles();
  const discount = useSelector((state: RootState) => state.order.discount);
  const percentOff: number | undefined = useSelector((state: RootState) => state.order.quote?.discount?.coupon?.percent_off);
  const quote = useSelector((state: RootState) => state.order.quote);
  const items: any[] = quote?.lines?.data.map((i: any) => i).sort((a: any, b: any) => b.amount - a.amount);
  const renewalDate = (items?.find(i => i.amount > 0)?.period?.end || 0) * 1000;

  return (
    <Box p={2}>
      <Typography variant="subtitle1"><b>{intl.get('productinfo.whatsintodayscharge')}</b></Typography>
      <Box mb={1}>
        <Grid container spacing={1}>
          {items?.map((line, index) => (
            <React.Fragment key={index}>
              <Grid item xs={7}><Typography variant="body2">{line.description}</Typography></Grid>
              <Grid item xs={5} className={classes.amount}><Typography variant="body2">{formatCurrency(line.amount / 100)}</Typography></Grid>
            </React.Fragment>
          ))}
          {!!discount &&
            <>
              <Grid item xs={7}><Typography variant="body2">{intl.get('discount')} ({percentOff}%)</Typography></Grid>
              <Grid item xs={5} className={classes.amount}><Typography variant="body2">{formatCurrency(discount)}</Typography></Grid>
            </>
          }
          {!!quote?.tax &&
            <>
              <Grid item xs={7}><Typography variant="body2">{intl.get('tax')}</Typography></Grid>
              <Grid item xs={5} className={classes.amount}><Typography variant="body2">{formatCurrency(quote?.tax / 100)}</Typography></Grid>
            </>
          }
          <Grid item xs={7}><Typography variant="body2"><b>{intl.get('total')}</b></Typography></Grid>
          <Grid item xs={5} className={classes.amount}><Typography variant="body2"><b>{formatCurrency(quote?.total / 100)}</b></Typography></Grid>
        </Grid>
      </Box>
      <Typography variant="body2">{intl.get('productinfo.prorationMessage', { renewalDate: dateFormat.format(renewalDate) })}</Typography>
    </Box>
  );
};

const TodaysChargeInfoBubble = () => (
  <HtmlTooltip arrow placement="right" title={InfoBubbleContent()}>
    <InfoOutlinedIcon color="primary"/>
  </HtmlTooltip>
);

export default TodaysChargeInfoBubble;
