import React from 'react';
import { Paper, Box } from '@material-ui/core';
import ProductFeatureList from './ProductFeatureList';

const ProductSummary = () => (
  <Paper>
    <Box p={4}>
      <ProductFeatureList />
    </Box>
  </Paper>
);

export default ProductSummary;
