import { createSlice, PayloadAction, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import ecommerceApi from '../../api/ecommerce';
import { RootState } from '../../reducers';
import { Coupon } from '@lifesize/types/lib/ecommerce';
import { recalculate } from '../order/orderSlice';

type CouponsState = {
  code?: string;
  error?: SerializedError;
  isProcessing: boolean;
  coupon?: Coupon;
}

export const couponRequested = createAsyncThunk('coupon/get',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const code = state.coupons.code;
    if (code) {
      const coupon = await ecommerceApi.getCoupon(code);
      thunkApi.dispatch(couponsSlice.actions.setCoupon(coupon));
      await thunkApi.dispatch(recalculate());
    }
  });

const couponsSlice = createSlice({
  name: 'coupons',
  initialState: {
    isProcessing: false
  } as CouponsState,
  reducers: {
    setCode: (state, action: PayloadAction<string>) => { state.code = action.payload; },
    setCoupon: (state, action: PayloadAction<Coupon>) => { state.coupon = action.payload; }
  },
  extraReducers: builder => {
    builder.addCase(couponRequested.pending, (state) => {
      state.coupon = undefined;
      state.isProcessing = true;
      state.error = undefined;
    });
    builder.addCase(couponRequested.fulfilled, (state, action) => {
      state.isProcessing = false;
      state.error = undefined;
    });
    builder.addCase(couponRequested.rejected, (state, action) => {
      state.coupon = undefined;
      state.isProcessing = false;
      state.error = action.error;
    });
  }
});

export const {
  setCode,
  setCoupon
} = couponsSlice.actions;

export default couponsSlice.reducer;
