import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  link: {
    color: '#fff',
    fontWeight: 600,
    textDecoration: 'none',
  },
});

interface Props {
  label: string;
  url?: string;
}

export default function FooterLink(props: Props) {
  const classes = useStyles();
  return (
    <a className={classes.link} href={props.url}>
      {props.label}
    </a>
  );
}
