import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import Logger from 'js-logger';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './components/theme';
import store from './store';
import { storeUrlToken } from './features/account/accountSlice';
import { storeUrlReseller } from './features/order/orderSlice';
import { fetchLocale } from './features/localization/localizationSlice';

Logger.useDefaults();
Logger.log(`Lifesize eCommerce ${process.env.REACT_APP_VERSION}`);

store.dispatch(storeUrlToken());
store.dispatch(storeUrlReseller());
store.dispatch(fetchLocale());

render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
