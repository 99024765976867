import React from 'react';
import { Typography, Hidden, Box } from '@material-ui/core';
import { getPlanDescription } from '../../utils/plans';
import HtmlTooltip from '../../components/HtmlToolTip';
import ProductFeatureList from './ProductFeatureList';
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const ProductInfoBubble = () => {
  const order = useSelector((state: RootState) => state.order);
  const friendlyName = getPlanDescription(order);
  return (
    <>
      <Typography>{friendlyName} &nbsp;</Typography>
      <Hidden mdUp>
        <HtmlTooltip arrow placement="right" title={
          <Box p={2}>
            <ProductFeatureList />
          </Box>
        }>
          <InfoOutlinedIcon color="primary"/>
        </HtmlTooltip>
      </Hidden>
    </>
  );
};
export default ProductInfoBubble;
