import React from 'react';
import intl from 'react-intl-universal';
import { ListItem, ListItemIcon, List, ListItemText } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import useStyles from './styles';
import { productFriendlyName, getFeatures } from '../../utils/plans';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

const ProductFeatureList = () => {
  const classes = useStyles();
  const product = useSelector((state: RootState) => state.order.selectedBaseProduct);
  if (!product) {
    return null;
  }
  const features = getFeatures(product.featureProfile);
  const productName = product && productFriendlyName(product);
  return (
    <>
      <span className={classes.sectionTitle}>{intl.get('planFeatures', { planName: productName })}</span>
      {features &&
        <List dense={true}>
          {features.map((f, i) => (
            <ListItem className={classes.listItem} key={i.toString()}>
              <ListItemIcon classes={{ root: classes.checkIconRoot }}>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary={f} />
            </ListItem>
          ))}
        </List>
      }
    </>
  )
};

export default ProductFeatureList;
