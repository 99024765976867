import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Product } from '@lifesize/types/lib/ecommerce';
import ecommerceApi from '../../api/ecommerce';

export type AddonsState = {
  data: Product[] | null;
  error?: SerializedError;
  isLoading: boolean;
}

export const getAddons = createAsyncThunk('addons/get', async () => await ecommerceApi.getAddons());

const addonsSlice = createSlice({
  name: 'addons',
  initialState: {
    isLoading: false
  } as AddonsState,
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getAddons.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getAddons.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = undefined;
    });
    builder.addCase(getAddons.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  }
});

export default addonsSlice.reducer;
