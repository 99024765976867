import { FeatureProfile } from '@lifesize/types/lib/account';

function removeTokenFromUrl() {
  const currentUrl = new URL(document.location.href);
  currentUrl.searchParams.delete('a');
  currentUrl.searchParams.delete('jwt');
  window.history.replaceState({}, document.title, currentUrl.href);
}

function getLoginUrl() {
  if (!process.env.REACT_APP_LOGIN_URL) {
    throw new Error('REACT_APP_LOGIN_URL is not defined');
  }
  const loginUrl = new URL(process.env.REACT_APP_LOGIN_URL);
  loginUrl.searchParams.append('next', document.location.href);
  return loginUrl.href;
}

function getReseller() {
  const url = new URL(window.location.href);
  return url.searchParams.get('reseller');
}

function getJwt() {
  const url = new URL(window.location.href);
  return url.searchParams.get('jwt') || url.searchParams.get('a');
}

function goToPreviousWebsite(jwt?: string) {
  const url = new URL(window.location.href);
  const next = url.searchParams.get('next') || process.env.REACT_APP_WEBAPP_URL;
  if (!next) {
    return;
  }
  let nextUrl;
  switch (next) {
    case 'webapp':
    case 'manage':
      nextUrl = `https://${next}.${process.env.REACT_APP_DOMAIN}`;
      break;
    default:
      // GOLD-3382: We need clients to stop sending full URL in 'next' param
      nextUrl = /^http(s)?:/.test(next) ? next : process.env.REACT_APP_WEBAPP_URL;
  }
  if (!nextUrl) {
    return;
  }
  const newUrl = new URL(nextUrl);
  if (jwt) {
    newUrl.searchParams.append('a', jwt);
  }
  window.location.href = newUrl.href;
}

function isFeatureProfile(value: string): value is FeatureProfile {
  return ['FREEMIUM', 'DEFAULT', 'HOST_STANDARD', 'HOST_PLUS', 'HOST_ENTERPRISE'].includes(value);
}

function getBuyProduct() {
  const url = new URL(window.location.href);
  const plan = url.searchParams.get('plan');
  if (plan && isFeatureProfile(plan)) {
    return plan as FeatureProfile;
  }
}

export { getJwt, getReseller, goToPreviousWebsite, getBuyProduct, removeTokenFromUrl, getLoginUrl };
