import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Theme, Box, fade, makeStyles } from '@material-ui/core';
import intl from 'react-intl-universal';
import { RootState } from '../../reducers';
import { billingIntervalChanged } from './orderSlice';
import { AppDispatch } from '../../store';
import { formatCurrency } from '../../utils/locale';
import PlanToggleButtonContent from './PlanToggleButton';

const useStyles = makeStyles((theme: Theme) => ({
  toggleButtonRoot: {
    textTransform: 'none',
    color: `${fade(theme.palette.action.active, 0.87)}`,
    border: `2px solid ${fade(theme.palette.action.active, 0.12)} !important`,
    width: '50%'
  },
  toggleButtonLabel: {
    display: 'block',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toggleButtonSelected: {
    border: `2px solid ${fade(theme.palette.primary.main, 0.87)} !important`,
    color: `${fade(theme.palette.action.active, 0.87)} !important`,
    backgroundColor: `${fade(theme.palette.primary.main, 0.12)} !important`
  },
  toggleButtonGroupRoot: {
    width: '100%'
  },
  toggleButton: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
      borderRadius: 'inherit'
    },
    '&:not(:last-child)': {
      borderRadius: 'inherit'
    }
  }
}));

const PlanSelection = () => {

  const dispatch: AppDispatch = useDispatch();
  const billingInterval = useSelector((state: RootState) => state.order.billingInterval);
  const annualTotal = useSelector((state: RootState) => state.order.baseProductAnnualTotal) || 0;
  const monthlyTotal = useSelector((state: RootState) => state.order.baseProductMonthlyTotal) || 0;
  const showMonthlyPlan = useSelector((state: RootState) => state.order.showMonthlyPlan);
  const annualCostByMonth = (annualTotal || 0) / 12;
  const annualSavings = showMonthlyPlan && ((monthlyTotal || 0) - annualCostByMonth) * 12;
  const classes = useStyles();
  const toggleButtonClasses = {
    root: classes.toggleButtonRoot,
    label: classes.toggleButtonLabel,
    selected: classes.toggleButtonSelected
  };

  return (
    <Box mt={1}>
      <ToggleButtonGroup
        value={billingInterval}
        onChange={(_, value) => value && dispatch(billingIntervalChanged(value))}
        exclusive
        aria-label="plan billing interval" id="billingInterval"
        classes={{
          root: classes.toggleButtonGroupRoot
        }}
      >
        {showMonthlyPlan &&
          <ToggleButton value="monthly" aria-label="monthly plan"
            className={classes.toggleButton}
            classes={toggleButtonClasses}
          >
            <PlanToggleButtonContent
              title={intl.get('monthly')}
              monthlyCost={monthlyTotal}
              footnote={intl.get('billedMonthly')}
            />
          </ToggleButton>
        }
        <ToggleButton value="annual" aria-label="annual plan"
          className={classes.toggleButton}
          classes={toggleButtonClasses}
        >
          <PlanToggleButtonContent
            title={intl.get('annual')}
            monthlyCost={annualCostByMonth}
            footnote={intl.get('billedAnnually', { value: formatCurrency(annualTotal) })}
            savings={annualSavings}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box >
  );
};

export default PlanSelection;
