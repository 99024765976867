import intl from 'react-intl-universal';
import Logger from 'js-logger';
import { Localization, localizationConstants, localizationUtils } from '@lifesize/clients.localization';

const defaultLocale = localizationConstants.defaultLanguageId;

const numberFormat = new Intl.NumberFormat(window.navigator.language, { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' })

const locales: { [index: string]: JSON } = {
  'en-US': require('@lifesize/strings/clients.ecommerce/en.json'),
  'it-IT': require('@lifesize/strings/clients.ecommerce/it.json'),
  'ko-KR': require('@lifesize/strings/clients.ecommerce/ko.json'),
  'sv-SE': require('@lifesize/strings/clients.ecommerce/sv.json'),
  'de-DE': require('@lifesize/strings/clients.ecommerce/de.json'),
  'es-ES': require('@lifesize/strings/clients.ecommerce/es.json'),
  'fi-FI': require('@lifesize/strings/clients.ecommerce/fi.json'),
  'fr-FR': require('@lifesize/strings/clients.ecommerce/fr.json'),
  'ja-JP': require('@lifesize/strings/clients.ecommerce/ja.json'),
  'nl-NL': require('@lifesize/strings/clients.ecommerce/nl.json'),
  'no-NO': require('@lifesize/strings/clients.ecommerce/no.json'),
  'nb': require('@lifesize/strings/clients.ecommerce/no.json'),
  'pl-PL': require('@lifesize/strings/clients.ecommerce/pl.json'),
  'pt-BR': require('@lifesize/strings/clients.ecommerce/pt.json'),
  'cs-CZ': require('@lifesize/strings/clients.ecommerce/cs.json'),
  'ru-RU': require('@lifesize/strings/clients.ecommerce/ru-RU.json'),
  'zh-Hant': require('@lifesize/strings/clients.ecommerce/zh-Hant.json'),
  'zh-Hans': require('@lifesize/strings/clients.ecommerce/zh.json'),
};

const loadLocales = async (locale?: string) => {
  const urlParam = new URL(window.location.href);
  const paramLocale = urlParam.searchParams.get('locale');
  let localeCode = localizationUtils.getLanguageId(paramLocale || locale);
  if (!locales[localeCode]) {
    localeCode = localizationConstants.defaultLanguageId
  }

  const localization = new Localization({ id: localeCode });
  // Ensure that translation exists, otherwise fallback to two-letter code, or default
  await intl.init({
    currentLocale: localization.languageId,
    locales,
    fallbackLocale: defaultLocale
  });
  Logger.info('Language initialized to: ' + localization.language);
  Logger.info('Language fallback initialized to: ' + defaultLocale);
  return localization;
};

const formatCurrency = (value: number) => {
  const formattedValue = numberFormat.format(Math.abs(value));
  return (value < 0) ?
    `(${formattedValue})` :
    formattedValue;
};

export {
  loadLocales,
  formatCurrency
};
