import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { capitalize } from '@material-ui/core/utils';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElement, Card } from '@stripe/stripe-js';
import { Button, Box, CircularProgress, InputLabel, Typography } from '@material-ui/core';
import { getBuyProduct } from '../../utils/url';
import useStyles from './styles';
import StripeElementOptions from './StripeElementOptions';
import { RootState } from '../../reducers';
import {creditCardChanged, checkoutRequested, setError} from './orderSlice';
import { AppDispatch } from '../../store';
import Logger from 'js-logger';

const buyProduct = getBuyProduct();

const isComingFromSignupPage = !!buyProduct;

const BillingInfo = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const classes = useStyles();
  const elements = useElements();
  const stripe = useStripe();
  const dispatch: AppDispatch = useDispatch();
  const localization = useSelector((state: RootState) => state.localization);
  const order = useSelector((state: RootState) => state.order);
  const account = useSelector((state: RootState) => state.account.data);
  const products = useSelector((state: RootState) => state.products.data);
  const { hosts, isCreditCardComplete,
    paymentIntent, selectedBaseProduct, isLoading, minimumHosts, todaysTotal } = order;
  const existingCard = account?.paymentMethod?.card as Card;
  const cardBrand = capitalize(existingCard?.brand || '');
  const cardNumber = existingCard?.last4.padStart(16, '•');
  const cardExpiration = `${existingCard?.exp_month.toString().padStart(2, '0')} / ${existingCard?.exp_year.toString().substr(-2)}`;
  const postalCode = account?.paymentMethod?.billing_details?.address?.postal_code;
  const isExpansion = !!account?.subscription;
  const isBuyButtonEnabled =
    stripe &&
    !isLoading &&
    account?.isEcommerceSupported &&
    !isProcessing &&
    !!todaysTotal &&
    !!selectedBaseProduct &&
    (isCreditCardComplete || existingCard) &&
    hosts <= selectedBaseProduct?.maxQuantity &&
    hosts >= minimumHosts;

  window['handleCheckout'] = async (recaptchaToken: string) => {
    try {
      if (!stripe || !elements || !products || !selectedBaseProduct?.id || !recaptchaToken || isProcessing) {
        // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setIsProcessing(true);
      const cardElement = elements.getElement(CardElement) as StripeCardElement;
      await dispatch(checkoutRequested({ stripe, recaptchaToken, cardElement }));
    } catch (e) {
      Logger.log(`Unable to complete billing`);
      dispatch(setError(new Error(intl.get('failedReCaptcha'))));
    }
    setIsProcessing(false);
  };

  const checkOutMessage = isProcessing ? intl.get('processingPayment') : isComingFromSignupPage ? intl.get('completeCheckout') : intl.get('upgradeNow');

  return (
    <>
      <Box display="flex" flexDirection="column" p={4}>
        <span className={classes.sectionTitle}>{intl.get('billingInfo')}</span>
        <Box className={classes.lineBox}>
          <Box className={classes.leftBox}>
            <InputLabel htmlFor="cardNumber" required><b>{intl.get('creditCard')}</b></InputLabel>
          </Box>
          <Box className={classes.rightBox}>
            {isExpansion
              ? <Typography>{cardBrand}&nbsp;&nbsp;&nbsp;&nbsp;{cardNumber}&nbsp;&nbsp;&nbsp;&nbsp;{cardExpiration}&nbsp;&nbsp;&nbsp;&nbsp;{postalCode}</Typography>
              : <CardElement id="cardNumber" options={StripeElementOptions} onChange={e => dispatch(creditCardChanged(e))} />
            }
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" px={4}>
        {!paymentIntent &&
          <Button
            color="primary"
            variant="contained"
            disabled={!isBuyButtonEnabled}
            className="g-recaptcha"
            data-sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
            data-callback='handleCheckout'
            data-action='click'
          >
            {isProcessing &&
              <Box marginRight="1">
                <CircularProgress size={16} />&nbsp;
              </Box>
            }
            {checkOutMessage}
          </Button>
        }
      </Box>
      <Box display="flex" fontSize={12} justifyContent="flex-end" textAlign="right" px={4} py={2}>
        <span>{intl.get('agreeToTerms', { checkOutMessage })}&nbsp;</span>
        <a className={classes.link} href={localization.termsOfServiceUrl} rel="noopener noreferrer" target="_blank">{intl.get('termsOfServiceEnding')}</a>
      </Box>
    </>
  );
};

export default BillingInfo;
