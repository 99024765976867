import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { Order, Product } from '@lifesize/types/lib/ecommerce';
import fetchOptions from './fetchOptions';
import { StripeSubscription } from './StripeService';
import { Coupon } from '@lifesize/types/lib/ecommerce';

const responseError = async (response: Response) => {
  const error = new Error(await response.text() || response.statusText);
  if (response.status) {
    (error as any).code = response.status;
  }
  return error;
}

const getCoupon = async (id: string) => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = `/coupons/${id}`;
  const response = await fetch(url.href, fetchOptions.get);
  if (response.status === 200)
    return response.json() as Promise<Coupon>;
  throw await responseError(response);
}

const getAddons = async () => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = '/products/addons';
  const response = await fetch(url.href, fetchOptions.get);
  if (response.status === 200)
    return response.json() as Promise<Product[]>;
  throw responseError(response);
}

const getProducts = async () => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = '/products/base';
  const response = await fetch(url.href, fetchOptions.get);
  if (response.status === 200)
    return response.json() as Promise<Product[]>;
  throw await responseError(response);
}

const getSubscriptionQuote = async (order: Order, subscriptionId: string, signal?: AbortSignal) => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = `/subscriptions/${subscriptionId}/quote`;
  const response = await fetch(url.href, {
    ...fetchOptions.post,
    body: JSON.stringify(order),
    signal
  });
  if (response.status === 200) {
    return (await response.json());
  }
  throw await responseError(response);
}

const getSubscription = async (id: string) => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = `/subscriptions/${id}`;
  const response = await fetch(url.href, fetchOptions.get);
  return (await response.json()) as StripeSubscription;
}

const getPublicKey = async () => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = '/public-key';
  const response = await fetch(url.href, fetchOptions.get);
  const responseObject = await response.json();
  return responseObject.publicKey as string;
};

const getStripe = async () => {
  const key = await getPublicKey();
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  return await loadStripe(key);
}

const createSubscription = async (order: Order, recaptchaToken: string) => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = '/subscriptions';
  const response = await fetch(url.href, {
    ...fetchOptions.post,
    body: JSON.stringify({
      order,
      recaptchaToken
    })
  });
  if (response.status === 200) {
    return (await response.json()) as StripeSubscription;
  }
  throw await responseError(response);
}

const createPaymentMethod = async (order: Order, cardElement: StripeCardElement, stripe: Stripe) => {
  return stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
    billing_details: {
      email: order.email
    },
  });
}

const pollUntilAccountIsProvisioned = (id: string) => {
  return new Promise<StripeSubscription>((resolve, reject) => {
    let retries = 0;
    const checkIfProvisioned = async () => {
      const subscription = await getSubscription(id);
      if (subscription.metadata.isProvisioned === 'true') {
        return resolve(subscription);
      }
      if (++retries > 10) {
        return reject(new Error('Timeout waiting for account upgrade'));
      }
      setTimeout(() => checkIfProvisioned(), 3000);
    }
    checkIfProvisioned();
  });
};

/** Process regulatory (3D Secure) cards */
const process3ds = async (stripe: Stripe, clientSecret: string | null) => {
  if (!clientSecret) {
    throw new Error('Payment intent is missing secret');
  }
  const paymentConfirmation = await stripe.confirmCardPayment(clientSecret, {
    setup_future_usage: 'off_session'
  });
  if (paymentConfirmation.error) {
    throw new Error(paymentConfirmation.error.message);
  }
  if (!paymentConfirmation.paymentIntent) {
    throw new Error('Payment confirmation is missing payment intent');
  }
  return paymentConfirmation.paymentIntent;
}

const updateSubscription = async (order: Order, recaptchaToken: string, jwt?: string) => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = `/subscriptions/me`;
  if (jwt) {
    url.searchParams.append('jwt', jwt);
  }
  const response = await fetch(url.href, {
    ...fetchOptions.put,
    credentials: process.env.NODE_ENV === 'development' ? undefined : 'include',
    body: JSON.stringify({
      order,
      recaptchaToken
    }),
  });
  if (response.status === 200) {
    return (await response.json()) as StripeSubscription;
  }
  throw await responseError(response);
}

const reactivate = async (jwt?: string) => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = `/subscriptions/me/reactivate`;
  if (jwt) {
    url.searchParams.append('jwt', jwt);
  }
  const response = await fetch(url.href, {
    ...fetchOptions.post,
    credentials: process.env.NODE_ENV === 'development' ? undefined : 'include'
  });
  if (response.status === 200) {
    return (await response.json()) as StripeSubscription;
  }
  throw await responseError(response);
}

const checkOut = async (order: Order, cardElement: StripeCardElement, stripe: Stripe, recaptchaToken: string) => {
  const paymentMethod = await createPaymentMethod(order, cardElement, stripe);
  if (paymentMethod.error) {
    throw new Error(paymentMethod.error.message);
  }
  order.paymentMethodId = paymentMethod.paymentMethod?.id;
  let subscription = await createSubscription(order, recaptchaToken);
  let paymentIntent = !!subscription.latest_invoice &&
    typeof subscription.latest_invoice !== 'string' &&
    subscription.latest_invoice.payment_intent;
  if (!paymentIntent) {
    throw new Error('There was a problem processing your payment');
  }
  if (paymentIntent.status === 'requires_action') {
    paymentIntent = await process3ds(stripe, paymentIntent.client_secret);
  }
  // Poll until the subscription has been processed by Lifesize
  subscription = await pollUntilAccountIsProvisioned(subscription.id);
  return paymentIntent;
};

export default { getPublicKey, getStripe, getProducts, checkOut, getCoupon, getSubscriptionQuote, updateSubscription, getAddons, reactivate };
