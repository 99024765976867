import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  CircularProgress
} from '@material-ui/core';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { reactivateSubscription } from './orderSlice';
import { RootState } from '../../reducers';

const dateFormat = new Intl.DateTimeFormat(window.navigator.language, { year: 'numeric', month: 'short', day: '2-digit' });

const onClose = async (dispatch: AppDispatch) => {
  await dispatch(reactivateSubscription());
}

const ResubscribeDialog: React.FunctionComponent<{}> = () => {
  const dispatch: AppDispatch = useDispatch();

  const order = useSelector((state: RootState) => state.order);
  const { resubscribe, resubscribeProcessing } = order;
  const [ cancelledAt, setCancelledAt ] = useState<number>(resubscribe ? resubscribe * 1000 : 0);

  useEffect(
    () => {
      if (resubscribe) {
        return setCancelledAt(resubscribe * 1000);
      }
    },
    [ resubscribe ]
  )

  return (
    <Dialog
      open={!!resubscribe}
      onClose={() => onClose(dispatch)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby={'resubscribe'}
      aria-describedby={'resubscribe'}
    >
      <DialogTitle id="alert-dialog-title">{intl.get('reactivateSubscription')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.get('reactivateNotice', { date: dateFormat.format(cancelledAt) })}
        </DialogContentText>
        <DialogContentText id="alert-dialog-additional">
          {intl.get('reactivateUpgrade')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(dispatch)}
          disabled={resubscribeProcessing}
          color="primary"
          autoFocus
          disableFocusRipple={true}
        >
          {resubscribeProcessing &&
            <Box>
              <CircularProgress size={16} />&nbsp;&nbsp;
            </Box>
          }
          {intl.get('reactivate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResubscribeDialog;
