import { Account as eCommerceAccount } from '@lifesize/types/lib/ecommerce';
import fetchOptions from './fetchOptions';

const getAccount = async (jwt?: string) => {
  const url = new URL(process.env.REACT_APP_ECOMMERCE_ENDPOINT as string);
  url.pathname = '/accounts/me';
  if (jwt) {
    url.searchParams.append('jwt', jwt);
  }
  const response = await fetch(url.href, fetchOptions.get);
  if (response.status >= 200 && response.status <= 299) {
    const account: eCommerceAccount = await response.json();
    return account;
  }
  if (response.status === 404)
    return undefined;
  const error = new Error(response.statusText);
  if (response.status) {
    (error as any).code = response.status;
  }
  throw error;
};

export default { getAccount };
