import React, { useEffect, useState } from 'react';
import CheckoutForm from '../features/order/CheckoutForm';
import EcommerceApi from '../api/ecommerce';
import { Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Container, makeStyles } from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';
import Logger from 'js-logger';

const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    height: 0,
    minHeight: '100vh',
  },
  content: {
    flex: '1 0 auto',
  },
}));

const App: React.FunctionComponent = () => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const classes = useStyles();

  useEffect(() => {
    const getStripe = async () => {
      setStripe(await EcommerceApi.getStripe());
      Logger.log('Stripe loaded');
    }
    getStripe();
  }, []);

  return (
    <div className={classes.app}>
      <Elements stripe={stripe}>
        <Container maxWidth="lg" className={classes.content}>
          <Header />
          <CheckoutForm />
        </Container>
      </Elements>
      <Footer />
    </div>
  );
}

export default App;
