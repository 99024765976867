import React from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { makeStyles, Theme, Box } from '@material-ui/core';
import { RootState } from '../reducers';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontSize: '0.875rem',
    fontWeight: 600,
    letterSpacing: '0.025rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    }
  },
  link: {
    color: theme.palette.primary.main,
  },
  logo: {
    height: 40,
  },
}));

const Header = () => {
  const classes = useStyles();
  const localization = useSelector((state: RootState) => state.localization);
  return (
    <header>
      <Box className={classes.container} alignItems="center" display="flex" flex={1} justifyContent="space-between" p={2}>
        <a href="https://www.lifesize.com">
          <img
            className={classes.logo}
            alt="lifesize-logo"
            src="https://www.lifesize.com/wp-content/uploads/2020/12/lifesize-buddy-long-copy.svg"
          />
        </a>
        <Box>{intl.get('needHelp')} <a className={classes.link} href={localization.contactUrl}>{intl.get('contactUs')}</a></Box>
      </Box>
    </header>
  );
};

export default Header;
