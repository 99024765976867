import React from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Typography, Box, InputLabel } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styles';
import { RootState } from '../../reducers';
import { formatCurrency } from '../../utils/locale';
import TodaysChargeInfoBubble from './TodaysChargeInfoBubble';

const TodaysChargeLine = () => {
  const classes = useStyles();
  const total = useSelector((state: RootState) => state.order.todaysTotal) || 0;
  const account = useSelector((state: RootState) => state.account.data);
  const todaysCharges = formatCurrency(total);
  const todaysChargesContent = () => (
    <>
      <Typography variant="h6">{todaysCharges}</Typography>
      {!!total && <Box ml={1}>
        {account?.subscription
          ? <TodaysChargeInfoBubble />
          : <span>{intl.get('beforeTaxes')}</span>
        }
      </Box>}
    </>
  );

  return (
    <Box className={classes.lineBox}>
      <Box className={classes.leftBox}>
        <InputLabel><b>{intl.get('todaysCharge')}</b></InputLabel>
      </Box>
      <Box className={classes.rightBox} display="flex">
        {!isNaN(total)
          ? todaysChargesContent()
          : <Skeleton width={130} height={32} animation="wave" />
        }
      </Box>
    </Box>
  );
}

export default TodaysChargeLine;
