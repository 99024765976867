import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RootState } from '../../reducers';
import { formatCurrency } from '../../utils/locale';
import { recordAddonChanged, streamAddonChanged } from './orderSlice';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { Product } from '@lifesize/types/lib/ecommerce';

const streamingAddonsSort = (a: Product, b: Product) => {
  if (a.strmViewers && b.strmViewers) {
    return a.strmViewers - b.strmViewers;
  }
  return 0;
}
const RecordAddon = () => {
  const dispatch = useDispatch();
  const [strmViewers, setStrmViewers] = useState(500);
  const billingInterval = useSelector((state: RootState) => state.order.billingInterval);
  const hosts = useSelector((state: RootState) => state.order.hosts);
  const availableAddons = useSelector((state: RootState) => state.addons.data);
  const selectedAddons = useSelector((state: RootState) => state.order.addonProducts);
  const ownedItems = useSelector((state: RootState) => state.account.data?.subscription?.items?.data);

  const ownedItemsProductIds = ownedItems?.map((item: any) => item.plan.product);
  const recordingAddon = availableAddons?.find(a => a.addOnType === 'recording');
  const streamingAddons = availableAddons?.filter(a => a.addOnType === 'streaming').sort(streamingAddonsSort) || [];
  const ownedStreamingAddon = ownedItemsProductIds ? streamingAddons.find((ao: Product) => ownedItemsProductIds.includes(ao.id)) : undefined;
  // Users can upgrade but not downgrade the number of viewers
  const streamingOptions = streamingAddons.filter((ao: Product) => !ownedStreamingAddon || (ownedStreamingAddon?.strmViewers && ao.strmViewers && ao.strmViewers >= ownedStreamingAddon.strmViewers));
  const selectedOptionStreamingAddon = streamingAddons?.find(a => a.strmViewers === strmViewers);
  const selectedStreamingAddon = selectedAddons?.find(a => a.addOnType === 'streaming');
  const isRecordingAddonChecked = !!selectedAddons?.find(a => a.addOnType === 'recording');
  const isStreamingAddonChecked = !!selectedStreamingAddon;
  const alreadyOwnsRecording = !!ownedItems?.find((item: any) => item.plan.product === recordingAddon?.id);
  const alreadyOwnsStreaming = !!ownedStreamingAddon;
  const recordingUnitPrice = (billingInterval === 'monthly' ? recordingAddon?.monthlyPlan?.unitPrice : recordingAddon?.annualPlan?.unitPrice) || 0;
  const streamingUnitPrice = (billingInterval === 'monthly' ? selectedOptionStreamingAddon?.monthlyPlan?.unitPrice : selectedOptionStreamingAddon?.annualPlan?.unitPrice) || 0;
  const recordingPrice = recordingUnitPrice * hosts;
  const recordingPriceLabel = billingInterval === 'monthly' ?
    intl.get('addons.recordingMonthlyCost', { monthlyPrice: formatCurrency(recordingPrice) }) :
    intl.get('addons.recordingAnnualCost', { monthlyPrice: formatCurrency(recordingPrice / 12), annualPrice: formatCurrency(recordingPrice) });
  const streamingPriceLabel = billingInterval === 'monthly' ?
    intl.get('addons.streamingMonthlyCost', { monthlyPrice: formatCurrency(streamingUnitPrice) }) :
    intl.get('addons.streamingAnnualCost', { monthlyPrice: formatCurrency(streamingUnitPrice / 12), annualPrice: formatCurrency(streamingUnitPrice) });

  useEffect(
    () => {
      if (ownedStreamingAddon?.strmViewers) {
        setStrmViewers(ownedStreamingAddon.strmViewers);
      }
    },
    [ownedStreamingAddon]
  );
  const handleStreamCheckboxChange = (_: any, value: boolean) => {
    if (value) {
      dispatch(streamAddonChanged(selectedOptionStreamingAddon));
    } else {
      dispatch(streamAddonChanged());
    }
  };

  const handleStreamViewerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newStrmViewers = parseInt(String(event.target.value), 10);
    setStrmViewers(newStrmViewers);
    if (isStreamingAddonChecked) {
      const selection = streamingAddons.find((ao: Product) => ao.strmViewers === newStrmViewers);
      dispatch(streamAddonChanged(selection));
    }
  };

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary aria-controls="record-and-share-content" aria-label="Expand" id="record-and-share-header" expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{intl.get('addons.recordAndShare')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
        <FormControl component="fieldset" margin="dense" disabled={alreadyOwnsRecording}>
          <FormGroup>
            <FormControlLabel
              aria-label="record-and-share"
              checked={isRecordingAddonChecked}
              name="record-and-share"
              control={<Checkbox onChange={(_, value) => dispatch(recordAddonChanged(value))} />}
              label={recordingPriceLabel}
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" margin="dense">
          <FormGroup row>
            <FormControlLabel
              aria-label="live-streaming"
              name="live-streaming"
              checked={isStreamingAddonChecked}
              disabled={alreadyOwnsStreaming}
              control={<Checkbox onChange={handleStreamCheckboxChange} />}
              label={intl.get('addons.streamingLabel')}
            />
            <FormControlLabel
              aria-label="live-streaming-viewer-limit"
              name="live-streaming-viewer-limit"
              value={strmViewers}
              control={(
                <Select
                  onChange={handleStreamViewerChange}
                >
                  {streamingOptions.map(a => <MenuItem key={a.id} value={a.strmViewers}>{intl.get('addons.streamingOptionLabel', { viewerLimit: a.strmViewers })}</MenuItem>)}
                </Select>
              )}
              label={(
                <Box display="flex">
                  <Tooltip title={intl.get('addons.streamingTooltip')} placement="top" arrow>
                    <InfoOutlinedIcon color="primary"/>
                  </Tooltip>
                  {streamingPriceLabel}
                </Box>
              )}
            />
          </FormGroup>
        </FormControl>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default RecordAddon;
