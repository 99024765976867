import { createSlice, createAsyncThunk, SerializedError, PayloadAction } from '@reduxjs/toolkit';
import { Account as eCommerceAccount } from '@lifesize/types/lib/ecommerce';
import accountApi from '../../api/account';
import Logger from 'js-logger';
import { getLoginUrl, getJwt, removeTokenFromUrl } from '../../utils/url';
import { AppThunk } from '../../store';
import { RootState } from '../../reducers';

export type AccountState = {
  data?: eCommerceAccount;
  error?: SerializedError;
  isLoading: boolean;
  token?: string;
}

export function storeUrlToken(): AppThunk {
  return (dispatch) => {
    const token = getJwt();
    if (!token)
      return;
    dispatch(accountSlice.actions.setToken(token));
    if (process.env.NODE_ENV !== 'development') {
      removeTokenFromUrl();
    }
  }
}

export const getAccount = createAsyncThunk('account/get',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const token = state.account?.token;
    try {
      const response = await accountApi.getAccount(token);
      return response;
    }
    catch (e) {
      if (e.code === 401) {
        Logger.log('Unauthorized, navigating to login page...');
        document.location.replace(getLoginUrl());
      }
      else {
        throw e;
      }
    }
  }
);

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    isLoading: false
  } as AccountState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getAccount.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = undefined;
    });
    builder.addCase(getAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  }
});

export default accountSlice.reducer;
