import React from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Box, Typography, InputLabel } from '@material-ui/core';
import useStyles from './styles';
import { RootState } from '../../reducers';
import { formatCurrency } from '../../utils/locale';

const DiscountLine = () => {
  const classes = useStyles();
  const discount = useSelector((state: RootState) => state.order.discount);
  const coupon = useSelector((state: RootState) => state.coupons.coupon);
  const value = formatCurrency(discount || 0);
  return (
    <Box className={classes.lineBox}>
      <Box className={classes.leftBox}>
        <InputLabel htmlFor="coupon"><b>{intl.get('discount')}&nbsp;({coupon?.name})</b></InputLabel >
      </Box>
      <Box className={classes.rightBox} display="flex">
        <Typography variant="h6" color="primary"><b>({value})</b></Typography>
      </Box>
    </Box>
  );
}

export default DiscountLine;
