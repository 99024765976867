import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Button, Box, CircularProgress, TextField, InputLabel } from '@material-ui/core';
import useStyles from '../order/styles';
import { RootState } from '../../reducers';
import { setCode, couponRequested } from './couponsSlice';

const CouponLine = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const coupons = useSelector((state: RootState) => state.coupons);
  const { isProcessing, code, error } = coupons;
  const isButtonDisabled = !code || code.length < 9 || isProcessing;
  return (
    <Box className={classes.lineBox}>
      <Box className={classes.leftBox}>
        <InputLabel htmlFor="coupon"><b>{intl.get('coupon')}</b></InputLabel >
      </Box>
      <Box className={classes.rightBox} display="flex">
        <Box className={classes.couponBox}>
          <TextField id="coupon" variant="outlined" placeholder={intl.get('couponPlaceholder')} margin="dense" value={code}
            onChange={e => dispatch(setCode(e.target.value))} />
            {error &&
              <span className={classes.error}>{intl.get('couponInvalid')}</span>
            }
        </Box>
        <Box m={2}>
          <Button color="secondary" variant="contained" disabled={isButtonDisabled} onClick={() => dispatch(couponRequested())}>
            {isProcessing &&
              <Box>
                <CircularProgress size={16} />&nbsp;&nbsp;
              </Box>
            }
            {intl.get('couponApply')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CouponLine;
