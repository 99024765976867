import { FeatureProfile } from '@lifesize/types/lib/account';
import { Product } from '@lifesize/types/lib/ecommerce';
import OrderState from '../features/order/OrderState';
import { capitalize } from '@material-ui/core/utils';

export function getPlanDescription(order: OrderState): string {
  const name = order.selectedBaseProduct && productFriendlyName(order.selectedBaseProduct);
  return `${name} ${capitalize(order.billingInterval)} x ${order.hosts ? order.hosts : 0}`;
}

export function productFriendlyName(product: Product) {
  return product.name.replace('Lifesize ', '');
}

type ProductFeaturesMap = {
  [key in FeatureProfile]?: string[];
};

const features: ProductFeaturesMap = {
  'HOST_STANDARD': [
    'Unlimited meetings',
    'Personal meeting room',
    'Native desktop and mobile apps',
    'Host up to 100 participants',
    'Meet for up to 24 hours',
    'Single sign-on (SSO) support',
    'Centralized management console',
    'Online and chat support'
  ],
  'HOST_PLUS': [
    'Unlimited meetings',
    'Personal meeting room',
    'Native desktop and mobile apps',
    'Host up to 300 participants',
    'Meet for up to 24 hours',
    'Single sign-on (SSO) support',
    'Centralized management console',
    'Phone and email support',
    'Microsoft integrations',
    'Real-time meeting insights',
    '1 hour recording storage per host'
  ],
  'HOST_ENTERPRISE': [
    'Unlimited meetings',
    'Personal meeting room',
    'Native desktop and mobile apps',
    'Host up to 300 participants',
    'Meet for up to 24 hours',
    'Single sign-on (SSO) support',
    'Centralized management console',
    'Premium Phone and email support',
    'Microsoft integrations',
    'Real-time meeting insights',
    'Unlimited meeting recording',
    'Live stream 1,000 viewer events',
    'Unlimited US audio calling',
    'Branding and customization'
  ]
};

export const getFeatures = (plan: FeatureProfile) => {
  return features[plan] || [];
};
