import React from 'react';
import intl from 'react-intl-universal';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  error?: SerializedError;
  open: boolean;
  onClose: Function;
}

const FailureDialog: React.FunctionComponent<Props> = (props) => {
  const { error, open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby={error?.name}
      aria-describedby={error?.message}
    >
      <DialogTitle id="alert-dialog-title">{intl.get('problemProcessing')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <ErrorIcon color='error' />&nbsp;{error?.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" autoFocus>
          {intl.get('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FailureDialog;
