import React from 'react';
import { Chip, Theme, Typography, Box, makeStyles } from '@material-ui/core';
import intl from 'react-intl-universal';
import { formatCurrency } from '../../utils/locale';

type Props = {
  title: string;
  monthlyCost: number;
  footnote: string;
  savings?: number | false;
}

const useStyles = makeStyles((theme: Theme) => ({
  annualSavings: {
    position: 'absolute',
    top: theme.spacing(-2),
    right: theme.spacing(1.5),
    '& p': {
      lineHeight: 0
    }
  }
}));

const PlanToggleButtonContent: React.FunctionComponent<Props> = (props) => {
  const { title, monthlyCost, footnote, savings } = props;
  const classes = useStyles();
  return (
    <>
      <Typography>{title}</Typography>
      <Typography variant="h6">{formatCurrency(monthlyCost)}</Typography>
      <div><b>{intl.get('monthlyCost')}</b></div>
      <div><i>{footnote}</i></div>
      {!!savings &&
        <Chip className={classes.annualSavings} color="primary"
          label={
            <Box>
              <p>{intl.get('save')}</p>
              <p>{formatCurrency(savings)}</p>
            </Box>
          }
        />
      }
    </>
  )
};

export default PlanToggleButtonContent;
