import React from 'react';
import intl from 'react-intl-universal';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import FooterLink from './FooterLink';
import { RootState } from '../reducers';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.background.default,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    '@media (max-width: 50rem)': {
      flexDirection: 'column',
    },
  },
}));

const Footer = () => {
  const theme = useTheme();
  const localization = useSelector((state: RootState) => state.localization);
  const classes = useStyles(theme);
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <span>{intl.get('copyright', { year: new Date().getFullYear() })}&nbsp;</span>
        <span>
          <FooterLink label={intl.get('legal')} url={localization.legalUrl} />
          {' | '}
          <FooterLink label={intl.get('privacyPolicy')} url={localization.privacyUrl} />
          {' | '}
          <FooterLink label={intl.get('termsOfService')} url={localization.termsOfServiceUrl} />
        </span>
      </div>
    </footer>
  );
}

export default Footer;
