import { StripeCardElementOptions } from '@stripe/stripe-js';

const StripeElementOptions: StripeCardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      fontSize: '16px',
      color: '#000000',
      letterSpacing: '0.025em',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export default StripeElementOptions;
