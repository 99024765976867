import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { TextField, Box, InputLabel } from '@material-ui/core';
import useStyles from './styles';
import { RootState } from '../../reducers';
import { setReseller } from './orderSlice';

const ResellerLine = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const reseller = useSelector((state: RootState) => state.order.reseller);
  const resellerLocked = useSelector((state: RootState) => state.order.resellerLocked);
  return (
    <Box className={classes.lineBox}>
      <Box className={classes.leftBox}>
        <InputLabel htmlFor="reseller-name"><b>{intl.get('resellerLabel')}</b></InputLabel >
      </Box>
      <Box className={classes.rightBox}>
        <TextField
          id="reseller-name"
          variant="outlined"
          placeholder={intl.get('resellerPlaceholder')}
          margin="dense"
          value={reseller}
          onChange={e => dispatch(setReseller(e.target.value))}
          disabled={resellerLocked}
        />
      </Box>
    </Box>
  );
};

export default ResellerLine;
