const jsonHeaders = {
  'Content-Type': 'application/json'
};

const get: RequestInit = {
  method: 'GET',
  headers: jsonHeaders,
  cache: 'no-store',
  credentials: process.env.NODE_ENV === 'development' ? undefined : 'include'
};

const post: RequestInit = {
  method: 'POST',
  headers: jsonHeaders
};

const put: RequestInit = {
  method: 'PUT',
  headers: jsonHeaders,
  credentials: process.env.NODE_ENV === 'development' ? undefined : 'include'
};


export default { get, post, put };
