import React from 'react';
import intl from 'react-intl-universal';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

interface Props {
  isUpgrade: boolean;
  open: boolean;
  onClose: Function;
  paymentId?: string;
}

const SuccessDialog: React.FunctionComponent<Props> = (props) => {
  const { isUpgrade, open, onClose, paymentId } = props;
  const successTitle = isUpgrade ? intl.get('accountUpgrade') : intl.get('accountCreation');
  const successDescription = isUpgrade ? intl.get('accountHasUpgraded') : intl.get('accountHasCreated');

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby={successTitle}
      aria-describedby={`${successTitle} ${intl.get('accountReady')}`}
    >
      <DialogTitle id="alert-dialog-title">{successTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {successDescription} {intl.get('paymentId', { paymentId })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary" autoFocus>
          {intl.get('goBack')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
