import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Typography, Paper, Box, Grid, Hidden } from '@material-ui/core';
import Greeting from '../../components/Greeting';
import SuccessDialog from './SuccessDialog';
import FailureDialog from './FailureDialog';
import ProductSummary from './ProductSummary';
import { RootState } from '../../reducers';
import { setError, checkoutPageLoaded, checkoutCompleted } from './orderSlice';
import { AppDispatch } from '../../store';
import ProductInfo from './ProductInfo';
import BillingInfo from './BillingInfo';
import ResubscribeDialog from './ResubscribeDialog';

const CheckoutForm = () => {
  const dispatch: AppDispatch = useDispatch();
  const order = useSelector((state: RootState) => state.order);
  const account = useSelector((state: RootState) => state.account.data);
  const { paymentIntent, error, isLoading, resubscribe } = order;

  useEffect(() => {
    dispatch(checkoutPageLoaded());
  }, [dispatch]);

  return (
    <Box>
      <Box mx={2}>
        <Greeting />
      </Box>
      <Grid container direction="row">
        <Grid item xs={12} md={9}>
          <Box mb={2} mx={2}>
            <Paper>
              <ProductInfo />
            </Paper>
          </Box>
          <Box mx={2}>
            <Paper>
              <BillingInfo />
            </Paper>
          </Box>
          {!isLoading && !account?.isEcommerceSupported &&
            <Box m={2}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h5">{intl.get('contactToUpgrade')}</Typography>
                </Box>
              </Paper>
            </Box>
          }
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <ProductSummary />
          </Grid>
        </Hidden>
      </Grid>
      <SuccessDialog
        open={!!paymentIntent && !resubscribe}
        isUpgrade={!!account}
        onClose={() => dispatch(checkoutCompleted())}
        paymentId={paymentIntent?.id}
      />
      <FailureDialog
        onClose={() => dispatch(setError(undefined))}
        error={error}
        open={!!error && !resubscribe}
      />
      <ResubscribeDialog />
    </Box>
  );
};

export default CheckoutForm;
