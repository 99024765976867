import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: {
        fontSize: '1rem',
        letterSpacing: 0.5,
      },
    },
  },
  palette: {
    primary: {
      main: 'rgb(105, 99, 255);'
    },
    secondary: {
      main: 'rgb(31, 41, 51);'
    }
  },
  typography: {
    button: {
      fontWeight: 400,
    },
    fontFamily: 'azo-sans-web, sans-serif;',
  },
});

export default theme;
