import React from 'react';
import intl from 'react-intl-universal';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import RecordAddon from './RecordAddon';
import AudioConferencingAddon from './AudioConferencingAddOn';

const Addons = () => {
  const addons = useSelector((state: RootState) => state.addons.data);
  const recordingAddon = addons?.find(a => a.addOnType === 'recording');
  const pstnUs = addons?.find(a => a.addOnType === 'pstnUSCanada');
  const pstnIntl = addons?.find(a => a.addOnType === 'pstnIntl');
  return (
    <>
      <Typography variant="h6">{intl.get('addons.title')}</Typography>
      {recordingAddon &&
        <RecordAddon />
      }
      {pstnUs && pstnIntl &&
        <AudioConferencingAddon />
      }
    </>
  );
};

export default Addons;
