import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, Box } from '@material-ui/core';
import { RootState } from '../reducers';

const Greeting = () => {
  const account = useSelector((state: RootState) => state.account.data);
  const showCompanyName = account?.companyName && account?.displayName !== account?.companyName;
  const name = [
    account?.displayName,
    showCompanyName && '@',
    showCompanyName && account?.companyName
  ].filter(a => a).join(' ');
  return (
    <Box p={2}>
      {account ?
        <Typography variant="h5">{intl.get('greeting', { name })}</Typography> :
        <Skeleton width={500} height={32} animation="wave" />
      }
    </Box>
  );
};

export default Greeting;
