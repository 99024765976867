import { combineReducers } from 'redux';
import localization from '../features/localization/localizationSlice';
import order from '../features/order/orderSlice';
import account from '../features/account/accountSlice';
import products from '../features/products/productsSlice';
import coupons from '../features/coupons/couponsSlice';
import addons from '../features/addons/addonsSlice';

const rootReducer = combineReducers({
  addons,
  order,
  localization,
  account,
  products,
  coupons
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
