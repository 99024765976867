import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { BillingInterval } from '@lifesize/types/lib/account';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Product, Order } from '@lifesize/types/lib/ecommerce';
import { getProducts } from '../products/productsSlice';
import { getAddons } from '../addons/addonsSlice';
import { getAccount } from '../account/accountSlice';
import { getBuyProduct, getReseller, goToPreviousWebsite } from '../../utils/url';
import OrderState from './OrderState';
import { RootState } from '../../reducers';
import orderBuilder from '../../utils/orderBuilder';
import ecommerceApi from '../../api/ecommerce';
import { AppThunk } from '../../store';
import { subscriptionBaseItemSelector } from './selectors';
import { CheckoutParams } from './CheckoutParams';

export const initialState = {
  addonProducts: [] as Product[],
  billingInterval: 'monthly',
  hosts: 1,
  isCreditCardComplete: false,
  isLoading: true,
  isProcessing: false,
  minimumHosts: 1,
  resellerLocked: false,
  showMonthlyPlan: true,
  showYourCurrentPlan: false,
  resubscribe: false,
  resubscribeProcessing: false,
} as OrderState;

let recalculatePromise: any;

export const recalculate = createAsyncThunk('order/recalculate',
  async (_, thunkApi) => {
    if (recalculatePromise && recalculatePromise.abort)
      recalculatePromise.abort();
    const state = thunkApi.getState() as RootState;
    const { selectedBaseProduct, hosts } = state.order;
    const subscription = state.account?.data?.subscription;
    if (selectedBaseProduct && hosts >= 1) {
      const baseProductAnnualTotal = selectedBaseProduct.annualPlan && selectedBaseProduct.annualPlan.unitPrice * hosts;
      const baseProductMonthlyTotal = selectedBaseProduct.monthlyPlan && selectedBaseProduct.monthlyPlan.unitPrice * hosts;
      const baseItem = subscriptionBaseItemSelector(state);
      let discount = 0;
      let todaysTotal = 0;
      let quote;

      // Expansion
      if (subscription && baseItem?.quantity) {
        if (hosts >= baseItem?.quantity) {
          // TODO: Calculate add-ons with expansions
          const order: Order = orderBuilder.build(state.order, state.account.data, state.coupons.coupon);
          quote = await ecommerceApi.getSubscriptionQuote(order, subscription.id, thunkApi.signal);
          if (!quote.subscription_proration_date) {
            // No proration date means no billing changes were made
            todaysTotal = 0;
            discount = 0;
          } else {
            todaysTotal = quote.total / 100;
            discount = (quote.total - quote.tax - quote.subtotal) / 100;
          }}
      }

      // New subscription
      else {
        const { billingInterval, addonProducts } = state.order;
        const { coupon } = state.coupons;
        let subtotal = (billingInterval === 'monthly' && selectedBaseProduct.monthlyPlan ? baseProductMonthlyTotal : baseProductAnnualTotal) || 0;
        addonProducts
          .filter(addon => addon.baseProduct?.includes(selectedBaseProduct.id))
          .forEach(addon => {
            const addonPrice = billingInterval === 'monthly' && addon.monthlyPlan ?
              addon.monthlyPlan.unitPrice :
              addon.annualPlan?.unitPrice;
            if (addonPrice) {
              if (addon.addOnType === 'streaming') {
                subtotal += addonPrice;
              } else {
                subtotal += addonPrice * hosts;
              }
            }
          });
        if (coupon?.percentOff)
          discount = coupon.percentOff * subtotal;
        todaysTotal = subtotal - discount;
      }

      return {
        baseProductAnnualTotal,
        baseProductMonthlyTotal,
        discount,
        todaysTotal,
        quote
      }
    }
  }
);

export function storeUrlReseller(): AppThunk {
  return (dispatch) => {
    const reseller = getReseller();
    if (!reseller)
      return;
    dispatch(orderSlice.actions.lockReseller(reseller));
  }
}

export function checkoutCompleted(): AppThunk {
  return (_, getState) => {
    const state = getState();
    goToPreviousWebsite(state.account?.token);
  }
}

export const checkoutPageLoaded = createAsyncThunk('order/checkoutPageLoaded',
  async (_, thunkApi) => {
    // Load account and products
    await Promise.all([thunkApi.dispatch(getProducts()), thunkApi.dispatch(getAddons()), thunkApi.dispatch(getAccount())]);

    // Select first product when we get the list of products
    const state = thunkApi.getState() as RootState;
    const products = state.products.data;
    const subscription = state.account.data?.subscription;
    if (products && products.length > 0) {
      const buyProduct = getBuyProduct();
      const profile = buyProduct || 'HOST_STANDARD';
      const newProduct = products.find(p => p.featureProfile === profile);
      const showYourCurrentPlan = !!subscription || !buyProduct;
      thunkApi.dispatch(orderSlice.actions.setShowYourCurrentPlan(showYourCurrentPlan));
      let hosts: number | null = 1;
      if (subscription) {
        const baseItem = subscriptionBaseItemSelector(state);
        const product = products.find(product => baseItem.plan.product === product.id);
        hosts = baseItem?.quantity;
        if (!hosts || !product || !baseItem) {
          throw new Error('Corrupt subscription');
        }
        const interval = baseItem.plan.interval === 'month' ? 'monthly' : 'annual';
        thunkApi.dispatch(orderSlice.actions.setShowMonthlyPlan(interval === 'monthly')); // You can't expand from yearly to monthly
        subscription.items.data
          .filter((item: any) => item !== baseItem)
          .forEach((item: any) => {
            const addonProduct = state.addons?.data?.find(p => p.id === item.plan.product);
            if (addonProduct)
              thunkApi.dispatch(orderSlice.actions.addAddon(addonProduct));
          });
        thunkApi.dispatch(orderSlice.actions.setSelectedProduct(product));
        thunkApi.dispatch(orderSlice.actions.setBillingInterval(interval));
        thunkApi.dispatch(orderSlice.actions.setMinimumHosts(hosts));
      }
      else if (newProduct) {
        hosts = Math.max(newProduct.minQuantity);
        thunkApi.dispatch(orderSlice.actions.setSelectedProduct(newProduct));
      }
      thunkApi.dispatch(orderSlice.actions.setHosts(hosts));
    }

    // @ts-ignore
    if (subscription?.cancel_at) {
      // @ts-ignore
      thunkApi.dispatch(orderSlice.actions.setResubscribe(subscription.cancel_at))
    } else {
      recalculatePromise = thunkApi.dispatch(recalculate());
    }
  }
);

export function streamAddonChanged(value?: Product): AppThunk {
  return async (dispatch, getState) => {
    const state = getState() as RootState;
    if (value) {
      // Add recording if it has not been added yet
      if (!state.order.addonProducts.find(a => a.addOnType === 'recording')) {
        const recording = state.addons.data?.find(a => a.addOnType === 'recording');
        if (!recording)
          return;
        dispatch(orderSlice.actions.addAddon(recording));
      }
      // remove streaming addon
      state.order.addonProducts
        .filter(a => a.addOnType === 'streaming')
        .forEach(streaming => dispatch(orderSlice.actions.removeAddon(streaming)));
      // add new streaming selection
      dispatch(orderSlice.actions.addAddon(value));
    }
    else {
      // remove streaming addon
      state.order.addonProducts
        .filter(a => a.addOnType === 'streaming')
        .forEach(streaming => dispatch(orderSlice.actions.removeAddon(streaming)));
    }
    recalculatePromise = dispatch(recalculate());
  }
}

export function recordAddonChanged(value: boolean): AppThunk {
  return async (dispatch, getState) => {
    const state = getState() as RootState;
    const recording = state.addons.data?.find(a => a.addOnType === 'recording');
    if (!recording)
      return;
    if (value)
      dispatch(orderSlice.actions.addAddon(recording));
    else {
      // Remove streaming if it has been added
      state.order.addonProducts
        .filter(a => a.addOnType === 'streaming')
        .forEach(streaming => dispatch(orderSlice.actions.removeAddon(streaming)));

      dispatch(orderSlice.actions.removeAddon(recording));
    }
    recalculatePromise = dispatch(recalculate());
  }
}

export function pstnUsAddonChanged(value: boolean): AppThunk {
  return async (dispatch, getState) => {
    const state = getState() as RootState;
    const pstnUs = state.addons.data?.find(a => a.addOnType === 'pstnUSCanada');
    if (!pstnUs)
      return;
    if (value) {
      dispatch(orderSlice.actions.addAddon(pstnUs));
      // Remove PSTN Intl if it has been added
      if (state.order.addonProducts.find(a => a.addOnType === 'pstnIntl')) {
        const pstnIntl = state.addons.data?.find(a => a.addOnType === 'pstnIntl');
        if (!pstnIntl)
          return;
        dispatch(orderSlice.actions.removeAddon(pstnIntl));
      }
    }
    else
      dispatch(orderSlice.actions.removeAddon(pstnUs));
    recalculatePromise = dispatch(recalculate());
  }
}

export function pstnIntlAddonChanged(value: boolean): AppThunk {
  return async (dispatch, getState) => {
    const state = getState() as RootState;
    const pstnIntl = state.addons.data?.find(a => a.addOnType === 'pstnIntl');
    const pstnUs = state.addons.data?.find(a => a.addOnType === 'pstnUSCanada');
    if (!pstnIntl)
      return;
    if (value) {
      dispatch(orderSlice.actions.addAddon(pstnIntl));
      // Remove PSTN US/Canada if it has been added
      if (state.order.addonProducts.find(a => a.addOnType === 'pstnUSCanada')) {
        if (!pstnUs)
          return;
        dispatch(orderSlice.actions.removeAddon(pstnUs));
      }
    }
    else {
      if (pstnUs) {
        // Re-add PSTN US/Canada if already owned
        const ownsPstnUs = !!state.account.data?.subscription?.items?.data?.find((item: any) => item.plan.product === pstnUs?.id)
        if (ownsPstnUs)
          dispatch(orderSlice.actions.addAddon(pstnUs));
      }
      dispatch(orderSlice.actions.removeAddon(pstnIntl));
    }
    recalculatePromise = dispatch(recalculate());
  }
}

export function hostsChanged(hosts: number): AppThunk {
  return async (dispatch, getState) => {
    const state = getState() as RootState;
    // Validate product host limits
    const { selectedBaseProduct: selectedProduct } = state.order;
    if (selectedProduct && hosts >= 1) {
      if (hosts < selectedProduct.minQuantity || hosts > selectedProduct.maxQuantity) {
        const newProduct = state.products.data?.find(p => hosts >= p.minQuantity && hosts <= p.maxQuantity);
        if (newProduct) {
          dispatch(orderSlice.actions.setSelectedProduct(newProduct));
        }
      }
    }
    dispatch(orderSlice.actions.setHosts(hosts));
    recalculatePromise = dispatch(recalculate());
  }
}

export const checkoutRequested = createAsyncThunk('order/checkoutRequested',
  async (params: CheckoutParams, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    if (!state.account.data) {
      throw new Error('Missing account data');
    }
    const isExpansion = !!state?.account.data.subscription;
    if (!isExpansion && !params.stripe) {
      throw new Error('Missing parameter: stripe');
    }
    if (!isExpansion && !params.cardElement) {
      throw new Error('Missing parameter: card element');
    }
    const order: Order = orderBuilder.build(state.order, state.account.data, state.coupons.coupon);
    if (isExpansion) {
      const subscription = await ecommerceApi.updateSubscription(order, params.recaptchaToken, state.account.token);
      return subscription;
    }
    else {
      const paymentIntent = await ecommerceApi.checkOut(order, params.cardElement, params.stripe, params.recaptchaToken);
      return paymentIntent;
    }
  }
);

export const reactivateSubscription = createAsyncThunk('order/reactivateSubscription',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const subscription = await ecommerceApi.reactivate(state.account.token);
    recalculatePromise = thunkApi.dispatch(recalculate());
    return subscription;
  }
);

export function billingIntervalChanged(billingInterval: BillingInterval): AppThunk {
  return async (dispatch) => {
    dispatch(orderSlice.actions.setBillingInterval(billingInterval));
    recalculatePromise = dispatch(recalculate());
  }
}

export function selectedProductChanged(product: Product): AppThunk {
  return async (dispatch) => {
    dispatch(orderSlice.actions.setSelectedProduct(product));
    recalculatePromise = dispatch(recalculate());
  }
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    creditCardChanged: (state, action: PayloadAction<StripeCardElementChangeEvent>) => {
      if (action.payload.value.postalCode?.length === 5) {
        state.postalCode = action.payload.value.postalCode;
      }
      else {
        state.postalCode = undefined;
      }
      state.isCreditCardComplete = action.payload.complete;
    },
    setShowYourCurrentPlan: (state, action: PayloadAction<boolean>) => { state.showYourCurrentPlan = action.payload },
    setMinimumHosts: (state, action: PayloadAction<number>) => { state.minimumHosts = action.payload },
    setHosts: (state, action: PayloadAction<number>) => { state.hosts = action.payload },
    setReseller: (state, action: PayloadAction<string>) => { state.reseller = action.payload; },
    setBillingInterval: (state, action: PayloadAction<BillingInterval>) => { state.billingInterval = action.payload; },
    lockReseller: (state, action: PayloadAction<string>) => {
      state.reseller = action.payload;
      state.resellerLocked = true;
    },
    addAddon: (state, action: PayloadAction<Product>) => {
      state.addonProducts = [...state.addonProducts, action.payload];
    },
    removeAddon: (state, action: PayloadAction<Product>) => {
      state.addonProducts = state.addonProducts.filter(a => a.id !== action.payload.id);
    },
    setError: (state, action: PayloadAction<Error | undefined>) => { state.error = action.payload; },
    setResubscribe: (state, action: PayloadAction<number | false>) => { state.resubscribe = action.payload; },
    setIsLoading: (state, action: PayloadAction<boolean>) => { state.isLoading = action.payload; },
    setShowMonthlyPlan: (state, action: PayloadAction<boolean>) => { state.showMonthlyPlan = action.payload; },
    setSelectedProduct: (state, action: PayloadAction<Product>) => {
      state.selectedBaseProduct = action.payload;
      if (!action.payload.monthlyPlan) {
        state.billingInterval = 'annual';
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(recalculate.pending, (state) => {
      state.todaysTotal = undefined;
    });
    builder.addCase(recalculate.fulfilled, (state, action) => ({
      ...state,
      ...action.payload
    }));
    builder.addCase(recalculate.rejected, (state, action) => {
      if (action.error.name === 'AbortError')
        return;
      state.error = action.error
    });
    builder.addCase(checkoutPageLoaded.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(checkoutPageLoaded.rejected, (state, action) => {
      state.error = action.error
    });
    builder.addCase(checkoutRequested.pending, (state) => {
      state.isProcessing = true;
    });
    builder.addCase(checkoutRequested.rejected, (state, action) => {
      state.error = action.error;
      state.isProcessing = false;
    });
    builder.addCase(checkoutRequested.fulfilled, (state, action) => {
      state.paymentIntent = action.payload;
      state.isProcessing = false;
    });
    builder.addCase(reactivateSubscription.pending, (state) => {
      state.resubscribeProcessing = true;
    });
    builder.addCase(reactivateSubscription.fulfilled, (state, action) => {
      state.resubscribe = false;
      state.resubscribeProcessing = false;
    })
  }
});

export const {
  setReseller,
  lockReseller,
  setBillingInterval,
  creditCardChanged,
  setError,
  setResubscribe,
  setIsLoading
} = orderSlice.actions;

export default orderSlice.reducer;
