import { createSelector } from 'reselect';
import { RootState } from '../../reducers';

const accountSelector = (state: RootState) => state.account.data;
const productsSelector = (state: RootState) => state.products.data;

export const subscriptionBaseItemSelector = createSelector(
  [accountSelector, productsSelector],
  (account, products) => account?.subscription?.items.data.find((item: any) => products?.find(product => item.plan.product === product.id)?.type === 'base')
);

export const planDescriptionSelector = createSelector(
  [accountSelector, subscriptionBaseItemSelector],
  (account, baseItem) => {
    if (account?.subscription) {
      return `${baseItem.plan?.nickname} x ${baseItem.quantity}`;
    }
    switch (account?.plan) {
      case 'TRIAL':
        return 'Trial';
      case 'FREEMIUM':
        return 'Free';
      case 'HOST_ENTERPRISE':
        return 'Enterprise';
      case 'HOST_PLUS':
        return 'Plus';
      case 'HOST_STANDARD':
        return 'Standard';
    }
    return 'Unknown';
  }
);
