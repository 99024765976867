import { Middleware } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';
import { getAccount } from '../account/accountSlice';
import { checkoutRequested, checkoutPageLoaded } from '../order/orderSlice';
import Analytics from 'react-ga';

const analyticsMiddleware: Middleware<{}, RootState> = store => (next) => (action) => {
  switch (action.type) {
    case getAccount.fulfilled.type:
      Analytics.initialize('UA-1282785-1');
      Analytics.pageview(window.location.pathname + window.location.search);
      const account = action.payload;
      window.aptrinsic('identify', {
        //User Fields
        'id': account.userUUID, // Required for logged in app users
        'email': account.email
      }, {
        //Account Fields
        'id': account.groupUUID, //Required
        'name': account.companyName
      });
      break;
    case checkoutRequested.pending.type:
      window.aptrinsic('track', 'checkoutStarted');
      break;
    case checkoutRequested.rejected.type:
      window.aptrinsic('track', 'checkoutFailed', { 'error': action.error.message });
      break;
    case checkoutRequested.fulfilled.type:
      window.aptrinsic('track', 'checkoutCompleted');
      Analytics.event({
        action: 'checkout complete',
        category: 'ecom',
      });
      break;
    case checkoutPageLoaded.fulfilled.type:
      window.aptrinsic('track', 'loaded');
      break;
  }
  return next(action);
}

export default analyticsMiddleware;
