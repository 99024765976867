import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { Typography, TextField, Box, InputLabel } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ProductInfoBubble from './ProductInfoBubble';
import useStyles from './styles';
import { RootState } from '../../reducers';
import ResellerLine from './ResellerLine';
import { hostsChanged } from './orderSlice';
import { AppDispatch } from '../../store';
import CouponLine from '../coupons/CouponLine';
import DiscountLine from './DiscountLine';
import PlanSelection from './PlanSelection';
import TodaysChargeLine from './TodaysChargeLine';
import Addons from './Addons';
import { planDescriptionSelector } from './selectors';

const ProductInfo = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const order = useSelector((state: RootState) => state.order);
  const account = useSelector((state: RootState) => state.account.data);
  const { hosts, minimumHosts, selectedBaseProduct: selectedProduct, discount, showYourCurrentPlan } = order;
  const coupon = useSelector((state: RootState) => state.coupons?.coupon);
  const planDescription = useSelector(planDescriptionSelector);

  return (
    <Box display="flex" flexDirection="column" p={4}>
      <span className={classes.sectionTitle}>{intl.get('planInfo')}</span>
      {account && showYourCurrentPlan &&
        <Box className={classes.lineBox}>
          <Box className={classes.leftBox}>
            <InputLabel><b>{intl.get('yourCurrentPlan')}</b></InputLabel>
          </Box>
          <Box className={classes.rightBox}>
            <Typography>
              {planDescription}
            </Typography>
          </Box>
        </Box>
      }
      <Box className={classes.lineBox}>
        <Box className={classes.leftBox}>
          <InputLabel><b>{account ? intl.get('newSubscriptionPlan') : intl.get('subscriptionPlan')}</b></InputLabel>
        </Box>
        <Box className={classes.rightBox} display="flex">
          {selectedProduct ?
            <ProductInfoBubble /> :
            <Skeleton width={130} height={24} animation="wave" />}
        </Box>
      </Box>
      <Box className={classes.lineBox}>
        <Box className={classes.leftBox}>
          <InputLabel htmlFor="hosts" required><b>{intl.get('numberOfHosts')}</b> {intl.get('includesYou')}</InputLabel>
        </Box>
        <Box className={classes.rightBox} display="flex">
          <TextField
            className={classes.hostsInput}
            required={true}
            id="hosts"
            type="number"
            value={hosts}
            variant="outlined"
            autoFocus={true}
            margin="dense"
            InputProps={{
              inputProps: {
                min: minimumHosts,
                max: 99999,
                style: {
                  textAlign: 'right'
                }
              }
            }}
            onChange={e => dispatch(hostsChanged(parseInt(e.target.value)))}
          />
        </Box>
      </Box>
      <PlanSelection />
      <Box my={1} />
      <Addons />
      <Box my={1} />
      {coupon && discount &&
        <DiscountLine />
      }
      <TodaysChargeLine />
      {account && !account.subscription &&
        <>
          <CouponLine />
          <ResellerLine />
        </>
      }
      <Box display="flex" fontSize={12} justifyContent="flex-end" textAlign="right">
        {intl.getHTML('productinfo.disclaimer')}
      </Box>
    </Box>
  );
}

export default ProductInfo;
